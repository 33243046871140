<template>
  <div class="apply">
        <div v-if="!isSuccess">
            <div class="geren" v-if="co_mode==1">
            <ul>
                <li>
                    <div class="label">当前合作方式:</div><div class="val">个人</div>
                </li>
                <li>
                    <div class="label">月可申请额度:</div><div class="val">{{price}}（元）</div>
                </li>
                <li>
                    <div class="label">综合服务费率:</div><div class="val">7% <span>（1000元以下不收取）</span></div>
                </li>
                <li>
                    <div class="label">申请与发放:</div><div class="val">1-3个工作日内发放</div>
                </li>
            </ul>
            </div>
            <div class="sixTypes">
                    <div class="onetype"  style="padding-top: 40px">
                        <div class="wrap" :class="onetype?'wrapHeight':''">
                            <div class="inputWrap">
                                <div class="label">申请金额</div>
                                <div class="inputBoxMoney">
                                    <span>￥</span><input type="number" v-model="typeFileList1.amount"  @input="addMoney" placeholder="请填写金额">
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
            <div class="applyPrice" v-if="co_mode==1">
                <div class="cardPrice">
                        <!-- <p class="name">申请金额（元）</p>
                        <div class="inputPrice">
                            <span>￥</span><input type="number" v-model="number" @blur="inspect" @focus="originVal">
                        </div>
                        <div class="tip">{{tip}}</div> -->
                        <div class="applyBank">
                            <div class="label">申请到</div>
                            <div class="r" @click="bankShowPopup">
                                <span>{{bankInfo}}</span>
                                <van-icon name="arrow" color="rgba(119,118,118,1)" />
                            </div>
                        </div>
                </div>
            </div>
            <div class="fixedBot" >
                <div class="wrap">
                    <div class="left">申请金额：<span>￥{{number}}</span></div>
                    <van-button type="primary" size="small" color="#F15223" round  @click="submitApply">确认提交</van-button>
                </div>
            </div>
        </div>
        <div v-else class="success">
            提交成功
            <p>请关闭当前弹层，刷新页面查看数据</p>
        </div>
        <van-popup
            v-model="bankShow"
            closeable
            close-icon-position="top-left"
            position="bottom"
            :style="{ height: '50%' }"
            >
                <ul class="bankList">
                    <li v-for="(v,i) in bankList" :key="i" @click="selectBank(v.account_bank,v.account_num,v.id,v.account_num_real)">
                        <span>{{v.account_bank}}( {{v.account_num}} )</span>
                        <van-icon name="success" color="rgba(241,82,35,1)" v-if="v.id==bankId" />
                    </li>
                </ul>
        </van-popup>
        <van-dialog v-model="submitPopupShow" title=" " closeOnClickOverlay :showConfirmButton="false" :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title">
                    <p class="name">申请金额</p>
                    <p class="price">￥{{number}}</p>
                </div>
                <ul>
                    <li>
                        <span>申请金额</span> <span>￥{{number}}</span>
                    </li>
                     <li>
                        <span>预计服务费（7%）</span> <span>￥{{ratePrice}}</span>
                    </li>
                     <li>
                        <span>预计实收金额</span> <span>￥{{realPrice}}</span>
                    </li>
                </ul>
                <div class="tip">预计1-3个工作日审核通过</div>
                <div class="submitBtn" @click="submitBtn">
                    确认提交
                </div>
            </div>
        </van-dialog>
  </div>
</template>

<script>
import api from "@/api/api"
export default {
    data(){
        return{
            clientHeight:document.documentElement.clientHeight,
            noBank:null,
            openid:"oftPL6mLE4pyfl5-SijSk9JM_KPM", login:localStorage.getItem('login_token'),/* login:"gKm5AUr3FaMG74EUSYbE9kvb6K72c5yNdVtRxvSjuXdHeXrGFQ", */
            realPrice:0,ratePrice:0,price:0,
            user:"",
            bankInfo:"",bankId:0,
            co_mode:1,/* 合作方式 1公司2个体户*/
            id:"",/* 用户id */
            bankList:[],
            showSale:false,
            submitPopupShow:false,/* 提交弹框 */
            number:0,originNumberType:0,originNumber:0,/* 金额 */
            invoiceImg:require("@/img/apply/icon_xuanzhong.png"),invoiceImgDefault:require("@/img/apply/icon_weixuanzhong.png"),
            uploadInvoiceImg:require("@/img/apply/shangchuanzhizhifapiao.png"),uploadPdf:require("@/img/apply/shangchuanpdf.png"),
            openImg:require("@/img/apply/zhankai.png"),
            bankShow:false,/* 银行弹出框 */
            twoTypeNumber:"",twoTypeindex:0,fiveTypeNumber:0,tip:"",sixTypeNumber:"",
            invoice_type:1,onetype:false,twotype:false,threetype:false,fourtype:false,fivetype:false,sixtype:false,
            fileList0: [],fileList1: [],fileList2: [],preview1:[],preview2:[],preview3:[],preview4:[],preview5:[],preview6:[],preview7:[],
            type6:["lecturer","host","conference"],
            typeFileList1:{
                type:1,
                remark:"",
                amount:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
            typeFileList3:{
                type:5,
                remark:"",
                amount:0,
                data:{
                    imgs:[
                        
                    ]
                }
            },
            typeFileList4:{
                type:3,
                remark:"",
                amount:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
            typeFileList2:{
                type:2,
                remark:"",
                data:{
                    group_num:0,
                    group_detail:[
                       
                    ]
                }
            },
            typeFileList5:{
                type:4,
                amount:"",
                remark:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
            typeFileList6:{
                type:6,
                remark:"",
                meet_type:"conference",
                data:{
                    "group_num": 0,
                    "group_detail": []
                }
            },
            data:[

            ],
            isSuccess:false,
            invoice_file:"", /* pdf文件 */
            invoice_img:"", /* 发票图片 */
        }
    },
    watch:{
        typeFileList6:function(val){
            console.log(val)
        }
    },
    methods:{
        // 金额计算
        async addMoney(){
            var money2=0,money6=0,total;

            

            total = money2 + money6 + this.typeFileList1.amount*1 + this.typeFileList3.amount * 1 + this.typeFileList4.amount * 1 + this.typeFileList5.amount * 1
            this.number = Math.abs(total.toFixed(0))
        },
        // 确认提交
        submitBtn(){
            
            // var data = JSON.stringify(this.data)
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
          api.apply.applyNew(this.openid,this.login,this.number,this.bankId,1,this.id).then(res =>{
                if(res.data.code == 0){
                    this.$toast.clear();
                    this.$toast(res.data.msg)
                    this.submitPopupShow = false
                    this.isSuccess = true
                }else{
                    this.$toast.clear();
                    this.$toast(res.data.msg)
                }
            }).catch(() =>{
                this.$toast.clear();
                this.submitPopupShow = false
            })

        },
        selectBank(v1,v2,v3,rel){
            this.nobank = rel
            this.bankId=v3
            this.bankInfo=v1+"("  + v2 + ")"
            this.bankShow = false
        },
        bankShowPopup(){
            if(this.co_mode != 1){
                return
            }
            this.bankShow = true
        },
        // 上传发票电子
        invoice1(file){
            let data=new FormData();
            file.status = 'uploading';
            file.message = '上传中...';
            data.append('file',file.file);
            api.my.uploadIMg(data).then(res =>{
                console.log(res)
                if(res.data.uploaded){
                    this.invoice_img=res.data.url
                    file.status = '';
                    file.message = '';
                }else{
                    file.status = 'failed';
                    file.message = '上传失败';
                }
            }).catch(req =>{
                console.log(req)
                this.$toast(req.data.msg)
            })
        },
        // 上传pdf格式
        invoice2(file){
            let data=new FormData();
            file.status = 'uploading';
            file.message = '上传中...';
            data.append('file',file.file);
            api.my.uploadIMg(data).then(res =>{
                if(res.data.uploaded){
                    this.invoice_file=res.data.url
                    file.status = '';
                    file.message = '';
                }else{
                    file.status = 'failed';
                    file.message = '上传失败';
                }
            }).catch(req =>{

                this.$toast(req.data.msg)
            })
        },
        // 上传纸质发票
        invoice3(file){
            let data=new FormData();
            file.status = 'uploading';
            file.message = '上传中...';
            data.append('file',file.file);
            api.my.uploadIMg(data).then(res =>{
                console.log(res)
                if(res.data.uploaded){
                   this.invoice_img=res.data.url
                    file.status = '';
                    file.message = '';
                }else{
                    file.status = 'failed';
                    file.message = '上传失败';
                }
            }).catch(req =>{
                this.$toast(req.data.msg)
            })
        },
        // 社群管理次数
        twoTypeNum(){
            if(this.twoTypeNumber < 50){
                
                this.preview2 = []
                this.typeFileList2.data.group_detail=[];
                for(var i=0;i<this.twoTypeNumber;i++){
                    this.$set(this.typeFileList2.data.group_detail,i,{name:"",num:"",imgs:[],role:"群主",amount:"",remark:"",})
                }
                this.addMoney()
            }else{
                this.$toast("数量不能超过50")
            }

        },
        // 讲课次数
        twoTypeNum6(){
            if(this.sixTypeNumber<50){
                this.preview6 = []
                this.typeFileList6.data.group_detail=[];
                for(var j=0;j<this.sixTypeNumber;j++){
                    this.typeFileList6.data.group_detail[j] =  {name:"",num:"",imgs:[],sign_table:"",content:"",amount:"",remark:"",address:""}
                }
                 this.addMoney()
            }else{
                this.$toast("数量不能超过50")
            }
        },
        opentype(i){ 
            switch(i){
                case 1:this.onetype = !this.onetype ;break;
                case 2:this.twotype = !this.twotype ;break;
                case 3:this.threetype = !this.threetype ;break;
                case 4:this.fourtype = !this.fourtype ;break;
                case 5:this.fivetype = !this.fivetype ;break;
                case 6:this.sixtype = !this.sixtype ;break;
            }
        },
        modifyType(i){
            this.invoice_type=i
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        // afterRead 
        onetypeUpload(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            if(file instanceof Array){
                file.forEach((v)=>{
                    let data=new FormData();
                    data.append('file',v.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            this.typeFileList1.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                })
            }else{
                let data=new FormData();
                    data.append('file',file.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            this.typeFileList1.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
            }
        },
        delete1(file,details){
            this.typeFileList1.data.imgs.splice(details.index,1)
            console.log(file,this.typeFileList1)
        },
        afterRead2(i){
            return file =>{
                if(file instanceof Array){
                    file.forEach((v)=>{
                        let data=new FormData(),that=this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file',v.file);
                        api.my.uploadIMg(data).then(res =>{
                            console.log(res)
                            if(res.data.uploaded){
                                that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            }else{
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req =>{
                            this.$toast(req.data.msg)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                }else{
                    let data=new FormData(),that=this;
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',file.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        this.$toast(req.data.msg)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            }
        },
        delete2(i){
            return  (file,details) =>{
                console.log(file,)
                this.typeFileList2.data.group_detail[i].imgs.splice(details.index,1)
                return true
            } 
        },
        afterRead3(file){
            if(file instanceof Array){
                file.forEach((v)=>{
                     let data=new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',v.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            this.typeFileList3.data.imgs.push(res.data.url)
                            this.typeFileList3.amount = 5000
                            this.addMoney()
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    }) 
                })
            }else{
                let data=new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file',file.file);
                api.my.uploadIMg(data).then(res =>{
                    console.log(res)
                    if(res.data.uploaded){
                        this.typeFileList3.data.imgs.push(res.data.url)
                        this.typeFileList3.amount = 5000
                        this.addMoney()
                        file.status = '';
                        file.message = '';
                    }else{
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req =>{
                    console.log(req)
                    file.status = 'failed';
                    file.message = '上传失败';
                })
            }
        },
        delete3(file,details){
            this.typeFileList3.data.imgs.splice(details.index,1)
            console.log(file,)
            this.typeFileList3.amount = 0
            this.addMoney()
        },
        afterRead4(file){
            if(file instanceof Array){
                file.forEach((v)=>{
                    let data=new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',v.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            this.typeFileList4.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                })
            }else{
                let data=new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file',file.file);
                api.my.uploadIMg(data).then(res =>{
                    console.log(res)
                    if(res.data.uploaded){
                        this.typeFileList4.data.imgs.push(res.data.url)
                        file.status = '';
                        file.message = '';
                    }else{
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req =>{
                    console.log(req)
                    file.status = 'failed';
                    file.message = '上传失败';
                })
            }
        },
        delete4(file,details){
            this.typeFileList4.data.imgs.splice(details.index,1)
            console.log(file,)
        },
        afterRead5(file){
            if(file instanceof Array){
                file.forEach((v)=>{
                    let data=new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',v.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            this.typeFileList5.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                })
            }else{
                let data=new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file',file.file);
                api.my.uploadIMg(data).then(res =>{
                    console.log(res)
                    if(res.data.uploaded){
                        this.typeFileList5.data.imgs.push(res.data.url)
                        file.status = '';
                        file.message = '';
                    }else{
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req =>{
                    console.log(req)
                    file.status = 'failed';
                    file.message = '上传失败';
                })
            }
        },
        delete5(file,details){
            this.typeFileList5.data.imgs.splice(details.index,1)
            console.log(file,)
        },
        afterRead6(i){
            return file =>{
                if(file instanceof Array){
                    file.forEach((v)=>{
                        let data=new FormData(),that=this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file',v.file);
                        api.my.uploadIMg(data).then(res =>{
                            console.log(res)
                            if(res.data.uploaded){
                                that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            }else{
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req =>{
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                }else{
                    let data=new FormData(),that=this;
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',file.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            }
        },
        delete6(i){
            return  (file,details) =>{
                console.log(file)
                this.typeFileList6.data.group_detail[i].imgs.splice(details.index,1)
                return true
            } 
        },
        afterRead7(i){
            return file =>{
                if(file instanceof Array){
                    file.forEach((v)=>{
                        let data=new FormData(),that=this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file',v.file);
                        api.my.uploadIMg(data).then(res =>{
                            console.log(res)
                            if(res.data.uploaded){
                                that.typeFileList6.data.group_detail[i].sign_table=res.data.url
                                file.status = '';
                                file.message = '';
                            }else{
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req =>{
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                }else{
                    let data=new FormData(),that=this;
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file',file.file);
                    api.my.uploadIMg(data).then(res =>{
                        console.log(res)
                        if(res.data.uploaded){
                            that.typeFileList6.data.group_detail[i].sign_table=res.data.url
                            file.status = '';
                            file.message = '';
                        }else{
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req =>{
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            }
        },
        delete7(i){
            return  (file) =>{
                console.log(file)
                this.typeFileList6.data.group_detail[i].sign_table= ''
                this.preview7[i]={}
                return true
            } 
        },
        // 重置
        reset(){
            this.twoTypeNumber=0,this.twoTypeindex=0,this.fiveTypeNumber=0,this.tip="",this.sixTypeNumber=0,
            this.fileList0= [],this.fileList1= [],this.fileList2= [],this.preview1=[],this.preview2=[],this.preview3=[],this.preview4=[],this.preview5=[],this.preview6=[],this.preview7=[],
            this.typeFileList1 = {
                type:1,
                remark:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
             this.typeFileList3 = {
                type:5,
                remark:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
             this.typeFileList4 = {
                type:3,
                remark:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
             this.typeFileList2 = {
                type:2,
                remark:"",
                data:{
                    group_num:0,
                    group_detail:[
                    ]
                }
            },
             this.typeFileList5 = {
                type:4,
                remark:"",
                data:{
                    imgs:[
                        
                    ]
                }
            },
             this.typeFileList6 = {
                type:6,
                remark:"",
                data:{
                    "type": "conference",
                    "group_num": 0,
                    "group_detail": []
                }
            },
            this.data=[]
            this.invoice_file = "", /* pdf文件 */
            this.invoice_img = "" /* 发票图片 */
            this.invoice_type=1
        },
        // 提交申请
        submitApply(){
            this.data=[]
            if(!this.bankId){
                this.$toast("请选择银行卡")
                return
            }
            // if((this.noBank+"").substring(0,10) == 6228480616){
            //     this.$toast("不支持该银行卡")
            //     return
            // }
            if(parseInt(this.number)<100){
                this.$toast("提现金额不能小于100")
                return
            }

            if(this.co_mode == 1){
                this.getCost()
            }else{
                this.submitBtn()
            }
        },
        getdata(){
            var that = this
            this.$toast({
                message: '加载中...',
                forbidClick: true,
            })
            api.apply.getdata(this.openid,this.login,1,this.id).then(res =>{
                console.log(res)
                this.$toast.clear()
                if(res.data.code == 0){
                    if(!res.data.default_bank)this.$toast({message:"请先添加收款账户",duration:3000});
                    that.bankInfo = res.data.default_bank.account_bank + "(" + res.data.default_bank.account_num + ")"
                    that.bankId = res.data.default_bank.id
                    that.noBank = res.data.default_bank.account_num_real
                    that.showSale = res.data.show_sale
                    if(that.co_mode == 1){
                        that.bankList = res.data.bank_info
                        this.price = res.data.month_limit
                    }
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req =>{
                console.log(req)
            })
        },
        getCost(){
            this.$toast({
                message: '加载中...',
                forbidClick: true,
            })
            api.apply.getCost(this.openid,this.login,this.number,1,this.id).then(res =>{
                this.$toast.clear()
                if(res.data.code == 0){
                    this.realPrice = res.data.resData.real_money
                    this.ratePrice = res.data.resData.fee
                    this.submitPopupShow = true
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req=>{
                console.log(req)
                this.$toast.clear()
            })
        }
    },
    created(){
        let str = new URLSearchParams(window.location.href.split("?")[1]) 
        str = Object.fromEntries(str)
        this.openid = str['openid']
        this.id =str['user_id']
        this.co_mode = this.$route.query.co_mode
        this.getdata()
    },
}
</script>

<style lang="less">
.apply{
    width: 100%;
    .geren{
        width: 100%;
        padding:.34rem;
        background-color:#fff;
        box-sizing: border-box;
        border-top:.02rem solid rgba(229,227,227,0.6);
        ul{
            li{
                margin-bottom:.36rem;
                .label{
                    display: inline-block;
                    width: 2.3rem;
                    text-align: right;
                    font-size:.24rem;
                    color:#B5B5B5;
                }
                .val{
                    display: inline-block;
                    text-align: right;
                    font-size:.24rem;
                    color:#0D0D0D;
                    padding-left:.42rem;
                    span{
                        font-size:.22rem;
                        color:rgba(241,82,35,1);
                    }
                }
            }
        }
    }
    .applyPrice{
        width: 100%; 
        padding:.34rem 0;
        box-sizing: border-box;
        background-color:#f6f6f6;
        .cardPrice{
            width: 100%;
            // height: 4.3rem;
            border-radius:.2rem;
            background-color:#fff;
            .name{
                line-height: .98rem;
                text-align: center;
                width: 100%;
                font-size:.24rem;
            }
            .inputPrice{
                width: 6.12rem;
                height: 1.04rem;
                border-radius:.1rem;
                background-color:#fff8f8;
                line-height:1.04rem;
                font-size:.56rem;
                margin: 0 auto;
                span{
                    width: 1.7rem;
                    display: inline-block;
                    padding-left:1rem;
                    box-sizing: border-box;
                }
                input{
                    border:none;
                    
                    width: 4.24rem;
                    height: 1.04rem;
                    border-radius:.1rem;
                    background-color:#fff8f8;
                }
            }
            .tip{
                width: 100%;
                padding:.26rem 0 0 .58rem;
                box-sizing: border-box;
                font-size:.24rem;
                height: .6rem;
                color:#f12243;
                margin-bottom:.42rem
            }
            .applyBank{
                width: 100%;
                box-sizing: border-box;
                padding:.4rem;
                height: 1.3rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .label{
                    font-size:.22rem;
                    color:rgba(119,118,118,1);
                }
                .r{
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
    .invoiceWrap{
        width: 100%;
        padding:0 .24rem;
        box-sizing: border-box;
        background-color:#f6f6f6;
        .invoiceTitle{
            display: flex;
            justify-content: space-between;
            line-height:.6rem;
            font-size:.2rem;
            color:#767575;
        }
        .invoiceBox{
            width: 100%;
            padding:.4rem .6rem;
            box-sizing: border-box;
            background-color:#fff;
            border-radius:.2rem;
            .typeInvoice{
                ul{
                    display: flex;
                    justify-content: space-between;
                    li{
                        width: 2rem;
                        height: .72rem;
                        border:.02rem solid #f15223;
                        line-height: .72rem;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius:.1rem;
                        img{
                            width: .36rem;
                            height: .36rem;
                            margin-right: .1rem;
                            vertical-align: middle;
                        }
                        span{
                            font-size:.22rem;
                            color:#F15223;
                            vertical-align: middle;
                        }
                        .active{
                            color:#D6D6D6;
                        }
                    }
                    .active{
                        border-color:#D6D6D6;
                    }
                }
            }
            .uploadImg{
                width: 100%;
                padding:.6rem 0 ;
                height: 4.1rem;
                box-sizing: border-box;
                .uploadBox{
                    width: 2.5rem;
                    position: relative;
                    margin: 0 auto;
                    img{
                        width: 100%;
                    }
                    .van-uploader{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        .van-uploader__upload{
                            width: 100%;
                            .van-uploader__preview{
                                width: 100%;
                                .van-uploader__preview-image{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .van-uploader__preview-image{
                        width: 2.5rem;
                    }
                    .imgHide{
                        opacity: 0;
                    }
                }
                .pdfBox{
                    width: 2.3rem;
                    height:2.66rem;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    p{
                        position: absolute;
                        bottom: -.8rem;
                        font-size:.22rem;
                        color:#FF6767;
                    }
                    .van-uploader{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        .van-uploader__upload{
                            width: 100%;
                            height: 100%;
                            
                        }
                        .van-uploader__file{
                            width: 2.3rem;
                            height:2.66rem;
                        }
                    }
                    .imgHide{
                        opacity: 0;
                        
                    }
                }
            }
        }
    }
    .sixTypes{
        width: 100%;
        background-color:#f6f6f6;
        .title{
            padding:.2rem 0 0 .24rem;
            font-size:.2rem;
            color:#767575;
            line-height: .6rem;
        }
        .onetype{
            width: 100%;
            background-color:#fff;
            margin-bottom: .24rem;
            .wrap{
                position: relative;
                padding:0 0 .86rem .3rem;
                box-sizing: border-box;
                overflow: hidden;
                .typeTitle{
                    span{
                        display: inline-block;
                        width: 2rem;
                        line-height: 1.24rem;
                        font-size:.22rem;
                        color:#000000;
                        font-weight: bold;
                        padding-left: .3rem;
                    }
                    .name{
                        text-align: left;
                        font-weight: 400;
                        color:rgba(74,72,72,1);
                        padding-left: 0;
                    }
                }
                .openImg{
                    position: absolute;
                    right: .5rem;
                    transition: all 1s;
                    width: .4rem;
                    img{
                        width: 100%;
                    }
                }
                .openactive{
                    bottom: .3rem;
                }
                .retractActive{
                    top:.4rem;
                    transform: rotate(180deg);
                }
                .uploadBox{
                    width: 100%;
                    padding-left:.3rem;
                    box-sizing: border-box;
                    .van-uploader__preview-image{
                        width: 1.76rem;
                    }
                }
                .inputWrap{
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content:space-around;
                    .label{
                        font-size: .2rem;
                    }
                    .inputBoxMoney{
                        width: 4rem;
                        height: .8rem;
                        padding:.18rem .3rem;
                        background-color:rgba(255,248,248,1);
                        box-sizing: border-box;
                        border:.02rem solid rgba(255,228,228,1);
                        display: flex;
                        align-items: center;
                        font-size:.2rem;
                        input{
                            width: 2.5rem;
                            font-size: .2rem;
                            border:none;
                            background-color:rgba(255,248,248,1);
                        }
                        
                    }
                    .bornone{
                        border:none;
                        background: #fff;
                        input{
                            width: 2.5rem;
                            border:none;
                            background: transparent;
                        }
                    }
                }
                .remarks{
                    width: 100%;
                    padding:.3rem ;
                    box-sizing: border-box;
                    p{
                       font-size:.22rem;
                       margin-bottom: .2rem;
                    }
                    textarea{
                        width: 96%;
                        height: 1.3rem;
                        font-size:.22rem;
                        border-radius: .1rem;
                        border:.02rem solid rgba(229,227,227,0.6);
                        padding: .2rem;
                        box-sizing: border-box;
                    }
                }
            }
            .wrapHeight{
                height: 1.22rem;
            }
        }
        .twoType{
            width: 100%;
            background-color:#fff;
            margin-bottom: .24rem;
            .wrap{
                position: relative;
                padding-bottom:.86rem;
                box-sizing: border-box;
                overflow: hidden;
                .typeTitle{
                    padding:0 0 0 .3rem;              
                    box-sizing: border-box;     
                    span{
                        display: inline-block;
                        width: 2rem;
                        line-height: 1.24rem;
                        font-size:.22rem;
                        color:#000000;
                        font-weight: bold;
                        padding-left: .3rem;
                    }
                    .name{
                        text-align: left;
                        font-weight: 400;
                        color:rgba(74,72,72,1);
                        padding-left: 0;
                    }
                    input{
                        border:none;
                        width: 2rem;
                        font-size: .2rem;
                    }
                    .line1{
                        line-height: .6rem;
                        padding-bottom: .46rem;
                    }
                    .line2{
                        font-size: .24rem;
                    }
                }
                .openImg{
                    position: absolute;
                    right: .5rem;
                    transition: all 1s;
                    width: .4rem;
                    img{
                        width: 100%;
                    }
                }
                .openactive{
                    bottom: .3rem;
                }
                .retractActive{
                    top:.4rem;
                    transform: rotate(180deg);
                }
                ul{
                    border-top:.02rem solid #f0eeee;
                    padding:0 .48rem;
                    width: 100%;
                    box-sizing: border-box;
                    li{
                        border-bottom:.02rem solid #f0eeee;
                        padding:.3rem 0;
                        box-sizing: border-box;
                        .name{
                            padding-left:.64rem;
                            color:rgba(99,79,198,1);
                            font-size:.2rem;
                        }
                        .inputbox{
                            width: 100%;
                            position: relative;
                            padding-left:1.14rem;
                            margin:.24rem 0 .28rem 0;
                            box-sizing: border-box;
                            font-size:.2rem;
                            .text{
                                position: relative;
                                width: 1.56rem;
                                display: inline-block;
                            }
                            .text::after{
                                position: absolute; 
                                content:"*";
                                right: .51rem;
                                top: .08rem;
                                font-size:.2rem;
                                font-weight: bold;
                                color:red;
                            }
                            .text1{
                                position: relative;
                                width: 1.56rem;
                                display: inline-block;
                            }
                            .text1::after{
                                position: absolute; 
                                content:"*";
                                right: -.05rem;
                                top: .08rem;
                                font-size:.2rem;
                                font-weight: bold;
                                color:red;
                            }
                            .text5{
                                position: relative;
                                width: 2rem;
                                display: inline-block;
                            }
                            .text5::after{
                                position: absolute; 
                                content:"*";
                                right: .51rem;
                                top: .08rem;
                                font-size:.2rem;
                                font-weight: bold;
                                color:red;
                            }
                            .text52{
                                position: relative;
                                width: 2rem;
                                display: inline-block;
                            }
                            .text52::after{
                                position: absolute; 
                                content:"*";
                                right: -.05rem;
                                top: .08rem;
                                font-size:.2rem;
                                font-weight: bold;
                                color:red;
                            }
                            input{
                                border: none;
                                margin-left: .58rem;
                                width: 2.3rem;
                                font-size: .2rem;
                            }
                        }
                        .inputbox::after{
                            position: absolute;
                            content:"";
                            left: .76rem;
                            top: .16rem;
                            width: .1rem;
                            height: .1rem;
                            background-color:rgba(255,134,92,1);
                            border-radius: 50%;
                        }
                        .uploadBox{
                            width: 100%;
                            padding-left:.3rem;
                            box-sizing: border-box;
                           .van-uploader__preview-image{
                                width: 1.76rem;
                            }
                        }
                        .radioWrap{
                            padding:.3rem .5rem;
                            display: flex;
                            justify-content: space-between;
                            .activeIcon{
                                display: inline-block;
                                width: .24rem;
                                height: .24rem;
                                border-radius: 50%;
                                border: .02rem solid rgba(133,128,128,1);
                                box-sizing: border-box;
                            }
                            .inactiveIcon{
                                display: inline-block;
                                position: relative;
                                width: .24rem;
                                height: .24rem;
                                border-radius: 50%;
                                box-sizing: border-box;
                                background: rgba(241,82,35,1);
                            }
                            .inactiveIcon::after{
                                position: absolute;
                                content: '';
                                width: .28rem;
                                height: .28rem;
                                border-radius: 50%;
                                box-sizing: border-box;
                                background: rgba(241,82,35,1);
                                border: .04rem solid #fff;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                top: .02rem;
                            }
                        }
                        .inputWrap{
                            width: 100%;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content:space-around;
                             font-size:.2rem;
                            .inputBoxMoney{
                                width: 4rem;
                                height: .8rem;
                                padding:.18rem .3rem;
                                background-color:rgba(255,248,248,1);
                                box-sizing: border-box;
                                border:.02rem solid rgba(255,228,228,1);
                                display: flex;
                                align-items: center;
                                font-size:.2rem;
                                line-height: .17rem;
                                input{
                                    width: 2.5rem;
                                    font-size: .2rem;
                                    border:none;
                                    background-color:rgba(255,248,248,1);
                                }
                            }
                        }
                    }
                }
                .remarks{
                    width: 100%;
                    padding:.3rem .7rem;
                    box-sizing: border-box;
                    p{
                       font-size:.22rem;
                       margin-bottom: .2rem;
                    }
                    textarea{
                        width: 96%;
                        height: 1.3rem;
                        font-size:.22rem;
                        border-radius: .1rem;
                        border:.02rem solid rgba(229,227,227,0.6);
                        padding: .2rem;
                        box-sizing: border-box;
                    }
                }
            }
            .wrapHeight{
                height: 2.2rem;
            }
        }
        .threeType{
             width: 100%;
             background-color:#fff;
             margin-bottom: .24rem;
            .wrap{
                position: relative;
                padding-bottom:.86rem;
                box-sizing: border-box;
                overflow: hidden;
                .typeTitle{
                    padding:0 0 0 .3rem;
                    box-sizing: border-box;
                    span{
                        display: inline-block;
                        width: 2rem;
                        line-height: 1.24rem;
                        font-size:.22rem;
                        color:#000000;
                        font-weight: bold;
                    }
                    .name{
                        text-align: left;
                        font-weight: 400;
                        color:#4A4848;
                        padding-left: 0;
                    }
                    input{
                        border:none;
                        width: 2rem;
                        font-size:.2rem;
                    }
                    .activeIcon{
                        display: inline-block;
                        width: .24rem;
                        height: .24rem;
                        border-radius: 50%;
                        border: .02rem solid rgba(133,128,128,1);
                        box-sizing: border-box;
                    }
                    .inactiveIcon{
                        display: inline-block;
                        position: relative;
                        width: .24rem;
                        height: .24rem;
                        border-radius: 50%;
                        box-sizing: border-box;
                    }
                    .inactiveIcon::after{
                        position: absolute;
                        content: '';
                        width: .28rem;
                        height: .28rem;
                        border-radius: 50%;
                        box-sizing: border-box;
                        background: rgba(241,82,35,1);
                        border: .04rem solid #fff;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        top: .02rem;
                    }
                    .radioWrap{
                        justify-content: space-around;
                    }
                    .van-radio__label{
                        width: auto;
                    }
                }
                .Overlay{
                    position: relative;
                    left: 0;
                    top:0;
                }
                .Overlay:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: #FEFEFE;
                    opacity: 0.62;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                }
                .openImg{
                    position: absolute;
                    right: .5rem;
                    transition: all 1s;
                    width: .4rem;
                    z-index: 20;
                    img{
                        width: 100%;
                    }
                }
                .openactive{
                    bottom: .3rem;
                }
                .retractActive{
                    top:.4rem;
                    transform: rotate(180deg);
                }
                ul{
                    border-top:.02rem solid #f0eeee;
                    padding:0 .48rem;
                    width: 100%;
                    box-sizing: border-box;
                    li{
                        border-bottom:.02rem solid #f0eeee;
                        padding:.3rem 0;
                        box-sizing: border-box;
                        .deleteBox{
                            width: 100%;
                            padding-right:.3rem;
                            box-sizing: border-box;
                            .text{
                                width: 80%;
                                color:#FA0202;
                                font-size:.2rem;
                            }
                            .delBtn{
                                display: inline-block;
                                padding:.05rem .2rem;
                                font-size:.2rem;
                                color:#fff;
                                background-color:#F15223;
                                text-align: center;
                                border-radius: .1rem;
                            }
                        }
                        .name{
                            padding-left:.3rem;
                            color:rgba(99,79,198,1);
                            font-size:.2rem;
                        }
                        .inputbox{
                            width: 100%;
                            position: relative;
                            padding-left:.84rem;
                            margin:.24rem 0 .28rem 0;
                            box-sizing: border-box;
                            font-size: .2rem;
                            .text{
                                position: relative;
                                width: 1.56rem;
                                display: inline-block;
                            }
                            .text::after{
                                position: absolute; 
                                content:"*";
                                right: 0rem;
                                top: .12rem;
                                font-size:.2rem;
                                font-weight: bold;
                                color:red;
                            }
                            input{
                                border: none;
                                margin-left: .68rem;
                                width: 2.3rem;
                                font-size: .2rem;
                            }
                        }
                        .inputbox::after{
                            position: absolute;
                            content:"";
                            left: .46rem;
                            top: .16rem;
                            width: .1rem;
                            height: .1rem;
                            background-color:rgba(255,134,92,1);
                            border-radius: 50%;
                        }
                        .uploadBox{
                            width: 100%;
                            padding-left:.3rem;
                            box-sizing: border-box;
                            .van-uploader__preview-image{
                                width: 1.76rem;
                            }
                            p{
                                font-size:.22rem;
                                line-height: .6rem;
                                font-weight: bold;
                            }
                        }
                        .inputWrap{
                            width: 100%;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content:space-around;
                             font-size:.2rem;
                            .inputBoxMoney{
                                width: 4rem;
                                height: .8rem;
                                padding:.18rem .3rem;
                                background-color:rgba(255,248,248,1);
                                box-sizing: border-box;
                                border:.02rem solid rgba(255,228,228,1);
                                display: flex;
                                align-items: center;
                                font-size:.2rem;
                                input{
                                    border:none;
                                    font-size: .2rem;
                                    width: 2.5rem;
                                     background-color:rgba(255,248,248,1);
                                }
                            }
                        }
                    }
                    .Overlay{
                        position: relative;
                        left: 0;
                        top:0;
                    }
                    .Overlay:after{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #FEFEFE;
                        opacity: 0.62;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                    }
                }
                .remarks{
                    width: 100%;
                    padding:.3rem ;
                    box-sizing: border-box;
                    p{
                       font-size:.22rem;
                       margin-bottom: .2rem;
                    }
                    textarea{
                        width: 100%;
                        height: 1.3rem;
                        font-size:.22rem;
                        border-radius: .1rem;
                        border:.02rem solid rgba(229,227,227,0.6);
                        padding: .2rem;
                        box-sizing: border-box;
                    }
                }
            }
            .wrapHeight{
                height: 3.9rem;
            }
        }
        .desc{
            font-size:.26rem;
            color:#C9C9C9;
            padding-left:.3rem;
            span{
                color:rgba(241,82,35,1);
            }
        }
    }
    .fixedBot{
        width:100%;
        background: #fff;
        font-size:.24rem;
        padding:.24rem .52rem;
        box-sizing: border-box;
        border-top:.02rem solid rgba(220,220,220,1);
        .wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
                span{
                    color:rgba(241,82,35,1);
                }
            }
        }
    }
    .bankList{
        width: 100%;
        padding-top:1rem;
        li{
            width: 100%;
            box-sizing: border-box;
            padding: 0 .8rem;
            line-height: 1.1rem;
            font-size:.24rem;
            color:#000;
            border-bottom:.02rem solid rgba(229,227,227,0.6);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
    .submitPopupBox{
        padding:0 .3rem .3rem .3rem;
        box-sizing: border-box;
        .title{
            color:#000;
            text-align: center;
            .name{
                font-size:.24rem;
            }
            .price{
                font-size:.44rem;
                margin: .3rem 0 .5rem 0;
            }
        }
        ul{
            li{
                display: flex;
                justify-content: space-between;
                font-size:.2rem;
                color:#000;
                margin-bottom:.26rem;
            }
        }
        .tip{
            font-size:.22rem;
            color:rgba(253,24,24,1);
            width: 100%;
            text-align: center;
            margin: .46rem 0 .7rem 0;
        }
        .submitBtn{
            width: 3.6rem;
            line-height: .6rem;
            background-color:rgba(241,82,35,1);
            border-radius:.1rem;
            text-align: center;
            margin: 0 auto;
            color:#fff;
        }
    }
    .success{
        text-align: center;
        line-height: 3rem;
        font-size: 1rem;
        p{
            font-size: .3rem;
            line-height: 1rem;
        }
    }
}
</style>